import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"
const Sports = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Sports and Extracurricular Facilities</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Sports and Extracurricular Facilities </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Sports and</span> <span className="light-bg"> Extracurricular Facilities</span></h3>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/indoor.png" alt="St. George’s College" /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Indoor Sports Complex</span></p>
                                    <p>St. George's College has an extensive sports complex that includes facilities for basketball, volleyball, badminton, squash and table tennis. The complex also features a well-equipped gymnasium for physical fitness and conditioning.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/football.png"  alt="St. George’s College" /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Playing Fields</span></p>
                                    <p>The college boasts expansive playing fields for cricket, football, hockey, and athletics. These fields provide ample space for students to engage in outdoor sports and develop their athletic skills.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/swimming.png" alt="St. George’s College"  /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Indoor Heated Swimming Pool</span></p>
                                    <p>The school's swimming pool offers opportunities for students to learn and practice swimming under the guidance of trained instructors. Swimming is an integral part of the SGC’s sports curriculum.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/music.png" alt="St. George’s College"  /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Modern Music and Dance Studio</span></p>
                                    <p>The School has a dedicated space for music and dance. It includes all modern instruments and sound systems enabling students to explore their creative talents and participate in various cultural activities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Sports